<template>
  <div class="Profile__Related">
    <load-more
      ref="profiles"
      :endpoint="endpoint"
      width="column is-8 is-offset-2"
    >
      <template
        slot="item"
        slot-scope="props"
      >
        <media-profile-card :profile="props.item" @relation-added="$refs.profiles.removeItem" />
      </template>
    </load-more>
  </div>
</template>

<script>
import LoadMore from '@/components/pagination/LoadMore'
import MediaProfileCard from '@/components/connect/discover/Card'

export default {
  components: {
    LoadMore, MediaProfileCard
  },

  computed: {
    endpoint () {
      return `connect/media-profiles?limit=5&scope=related&related_to=${this.$route.params.relationId}`
    }
  }
}
</script>

<style lang="scss">
  @import "~utils";

.Profile__Related {
  padding: $padding 0;
  margin-right: -$margin;
}
</style>
